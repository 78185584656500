import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { FaLaptopCode } from "@react-icons/all-files/fa/FaLaptopCode";
import { FaServer } from "@react-icons/all-files/fa/FaServer";
import { FaPaintBrush } from "@react-icons/all-files/fa/FaPaintBrush";
import { FaVial } from "@react-icons/all-files/fa/FaVial";
import { FaDatabase } from "@react-icons/all-files/fa/FaDatabase";
import { FaUsers } from "@react-icons/all-files/fa/FaUsers";
import {Link} from "gatsby";
import { useIntl } from "gatsby-plugin-intl"

const Services = () => {
  const intl = useIntl()
  return(
    <Layout>
      <SEO title="Development, IT Support & Outsourcing, Design, Biomed" />
      <div className="page-container services">
        <h2 className="page-header color-0 center">{intl.formatMessage({ id: "services-head" })}</h2>
        <section id="app-dev">
          <div className="container2">
            <div className="section-head color-1">
              <div className="section-head-icon-container">
                <FaLaptopCode className="section-head-icon"/>
              </div> 
              <h4 className="section-head-text">{intl.formatMessage({ id: "services-dev-head" })}</h4>
            </div>
            <p className="section-body color-1">{intl.formatMessage({ id: "services-dev" })}</p>
          </div>
        </section>
        <section id="it-support">
          <div className="container2">
            <div className="section-head color-1">
              <div className="section-head-icon-container">
                <FaServer className="section-head-icon"/>
              </div> 
              <h4 className="section-head-text">{intl.formatMessage({ id: "services-it-head" })}</h4>
            </div>
            <p className="section-body color-1">{intl.formatMessage({ id: "services-it" })}</p>
          </div>
        </section>
        <section id="design">
          <div className="container2">
            <div className="section-head color-1">
              <div className="section-head-icon-container">
                <FaPaintBrush className="section-head-icon"/>
              </div> 
              <h4 className="section-head-text">{intl.formatMessage({ id: "services-design-head" })}</h4>
            </div>
            <p className="section-body color-1">
            {intl.formatMessage({ id: "services-design" })}
            </p>
          </div>
        </section>
        <section id="biomed">
          <div className="container2">
            <div className="section-head color-1">
              <div className="section-head-icon-container">
                <FaVial className="section-head-icon"/>
              </div> 
              <h4 className="section-head-text">{intl.formatMessage({ id: "services-biomed-head" })}</h4>
            </div>
            <p className="section-body color-1">
              {intl.formatMessage({ id: "services-biomed" })}<br />
              {intl.formatMessage({ id: "services-biomed1" })}<br />
              {intl.formatMessage({ id: "services-biomed2" })}<br />
              {intl.formatMessage({ id: "services-biomed3" })}
            </p>
          </div>
        </section>
        <section id="data-analysis">
          <div className="container2">
            <div className="section-head color-1">
              <div className="section-head-icon-container">
                <FaDatabase className="section-head-icon"/>
              </div> 
              <h4 className="section-head-text">{intl.formatMessage({ id: "services-data-head" })}</h4>
            </div>
            <p className="section-body color-1">{intl.formatMessage({ id: "services-data" })}</p>
          </div>
        </section>
        <section id="staff-augment">
          <div className="container2">
            <div className="section-head color-1">
              <div className="section-head-icon-container">
                <FaUsers className="section-head-icon"/>
              </div> 
              <h4 className="section-head-text">{intl.formatMessage({ id: "services-staff-head" })}</h4>
            </div>
            <p className="section-body color-1">{intl.formatMessage({ id: "services-staff" })}
            </p>
          </div>
        </section>
        <div className="center cta-contain">
          <h4 className="color-0">Ready to kick off your next project? </h4>
          <Link to="/contact">
            <button className="mybtn-border color-2-bg color-1">Hire Us Today</button>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default Services
